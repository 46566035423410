<template>
  <DxDataGrid
      v-if="dataSource !== null"
      :data-source="dataSource"
      :show-borders="true"
      height="100%"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      :column-auto-width="true">
    
    <DxScrolling
        mode="infinite"/>

    <DxExport
        :enabled="true"
    />

    <DxColumn
        data-field="storeName"
    />


    <DxColumn
        data-field="yesterday"
        cell-template="yesterday"
    />

    <DxColumn
        data-field="rolling7"
        cell-template="rolling7"
    />

    <DxColumn
        data-field="period"
        cell-template="period"
    />

    <DxColumn
        data-field="ytd"
        cell-template="ytd"
    />

    <template #yesterday="{ data }">
      <div>
        <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeYesterday < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYesterday | formatMoneyNoCents }} </span>
          <span v-if="data.data.changeYesterday >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYesterday | formatMoneyNoCents }} </span>
        </div>

        <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeYesterday < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYesterday | formatNumberNoDecimal }} </span>
          <span v-if="data.data.changeYesterday >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYesterday | formatNumberNoDecimal }} </span>
        </div>
      </div>
    </template>

    <template #period="{ data }">
      <div>
        <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changePeriod < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod | formatMoneyNoCents }} </span>
          <span v-if="data.data.changePeriod >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod | formatMoneyNoCents }} </span>
        </div>

        <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changePeriod < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changePeriod | formatNumberNoDecimal }} </span>
          <span v-if="data.data.changePeriod >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changePeriod | formatNumberNoDecimal }} </span>
        </div>
      </div>
    </template>


    <template #rolling7="{ data }">

      <div>
        <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeRolling7 < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeRolling7 | formatMoneyNoCents }} </span>
          <span v-if="data.data.changeRolling7 >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeRolling7 | formatMoneyNoCents }} </span>
        </div>

        <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeRolling7 < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeRolling7 | formatNumberNoDecimal }} </span>
          <span v-if="data.data.changeRolling7 >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeRolling7 | formatNumberNoDecimal }} </span>
        </div>
      </div>

    </template>

    <template #ytd="{ data }">
      <div>
        <div v-if="metricType === 'NetSales'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeYtd < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYtd | formatMoneyNoCents }} </span>
          <span v-if="data.data.changeYtd >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYtd | formatMoneyNoCents }} </span>
        </div>

        <div v-if="metricType === 'GuestCount'" class="uk-flex uk-flex-column">
          <span class="top-metric">{{data.value | fixToOneDecimalPct}}</span>
          <span v-if="data.data.changeYtd < 0" class="fa fa-chevron-down uk-icon-center uk-text-danger">&nbsp;{{ data.data.changeYtd | formatNumberNoDecimal }} </span>
          <span v-if="data.data.changeYtd >= 0" class="fa fa-chevron-up uk-icon-center uk-text-success">&nbsp;{{ data.data.changeYtd | formatNumberNoDecimal }} </span>
        </div>
      </div>
    </template>


  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
} from 'devextreme-vue/data-grid';
import {createStore} from "devextreme-aspnet-data-nojquery";
import {Workbook} from "exceljs";
import {saveAs} from 'file-saver';
import {exportDataGrid} from "devextreme/excel_exporter";

export default {
  name: "CompCalculatorGrid",
  components: {
    DxDataGrid, DxColumn, DxPaging, DxPager, DxScrolling, DxExport
  },

  data() {
    return {
      compType: "All",
      storeType: "Domestic System Total",
      metricType: "NetSales",

      dataSource: null,
      corpFranItems: [
        {
          text: "Domestic System Total",
          value: "Domestic System Total"
        },

        {
          text: "Corporate",
          value: "Corporate"
        },

        {
          text: "Domestic Franchise",
          value: "Domestic Franchise"
        },
      ],

      compItems: [
        {
          text: "Comp",
          value: "Comp"
        },

      ],

      metricItems: [
        {
          text: "Net Sales",
          value: "NetSales"
        },
        {
          text: "Guest Count",
          value: "GuestCount"
        },
      ]
    }
  },



  methods: {
    storeTypeChanged(e) {

      this.storeType = e.value
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct-mobile`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: e.value,
            compType: this.compType,
            metricType: this.metricType,
            clientId: clientId
          }
        }
      });
    },

    onExporting(e) {

      let metricType = this.metricType;
      let storeType = this.storeType

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Comp % Store Overview Table');
      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function (dataGridRange) {
        for (let x = 4; x < dataGridRange.to.column + 1; x++) {
          for (let y = 2; y < dataGridRange.to.row + 1; y++) {

            let cell = worksheet.getRow(y).getCell(x)

            let cell2 = cell.value

            let cellValue = Number(cell2).toFixed(1);

            Object.assign(
                worksheet.getRow(y).getCell(x),
                {value: cellValue + "%"}
            );
          }
        }
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {type: "application/octet-stream"}),
              storeType + ' Comp % Store Overview Table ' + '(' + metricType +')'+'.xlsx');
        });

      });
    },

    compTypeChanged(e) {

      this.compType = e.value
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct-mobile`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: e.value,
            metricType: this.metricType,
            clientId: clientId
          }
        }
      });
    },

    metricTypeChanged(e) {

      this.metricType = e.value
      const url = this.getApiUrl();
      const clientId = this.getClientId();

      this.dataSource = createStore({
        loadUrl: `${url}/api/slims-report/store-overview-comp-pct-mobile`,
        onBeforeSend: (method, ajaxOptions) => {
          const token = this.getTokenSilently();
          ajaxOptions.headers = {
            Authorization: `Bearer ${token}`
          }
          ajaxOptions.data = {
            storeType: this.storeType,
            compType: this.compType,
            metricType: e.value,
            clientId: clientId
          }
        }
      });
    },

    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
            location: 'before',
          }, {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select Corporate Or Franchise",
              items: this.corpFranItems,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.corpFranItems[0].value,
              onValueChanged: this.storeTypeChanged.bind(this)
            }
          },

          {
            location: 'before',
            widget: 'dxSelectBox',
            options: {
              width: 200,
              placeHolder: "Select a Metric Type",
              items: this.metricItems,
              displayExpr: 'text',
              valueExpr: 'value',
              value: this.metricItems[0].value,
              onValueChanged: this.metricTypeChanged.bind(this)
            }
          },
      );
    },

  },

  mounted() {
    const url = this.getApiUrl();
    const clientId = this.getClientId();
    this.dataSource = createStore({
      loadUrl: `${url}/api/slims-report/store-overview-comp-pct-mobile`,
      onBeforeSend: (method, ajaxOptions) => {
        const token = this.getTokenSilently();
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`
        }
        ajaxOptions.data = {
          storeType: this.storeType,
          compType: this.compType,
          metricType: this.metricType,
          clientId: clientId
        }
      }
    });
  }

}
</script>

<style scoped>
.top-metric {
  font-size: large;
}

.total-column {
  font-weight: bold;
}
</style>