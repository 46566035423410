<template>
  <div class="metric-padding">
    
 
  <div class="uk-width-expand@l">
    <div class="uk-card uk-card-default uk-card-small uk-card-hover uk-flex-first@m">
      <div class="uk-card-header">
        <div class="uk-grid uk-grid-small">
          <div class="uk-width-auto"><h4>Store Overview</h4></div>
          <div class="uk-width-expand uk-text-right panel-icons">
          </div>
        </div>
      </div>
      <div class="uk-card-body">
        <div class="chart-container">
          <div class="channel-container">
            <mobile-store-overview></mobile-store-overview>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

import MobileStoreOverview from "./Components/MobileStoreOverview";

export default {
  name: 'App',
  components: {
      MobileStoreOverview
    
    
  },
  data() {
    return {
     
    };
  },
  
  mounted () {
   
  },

  created() {
  
  },

  beforeDestroy() {

  },
  
  methods: {}
  
}
</script>

<style lang="scss">
.metric-padding {
  padding: 10px;
}
</style>